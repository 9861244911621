@import "../../styles/theme/app/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid $main-bg-clr;
  position: relative;
  margin-bottom: 24px;

  .headerButtons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }
}
