$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------

//== Colors
//

//** Text muted color
$text-muted: #525252;
//** Horizontal line color.
$hr-border: #383838;
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000;
$gray-darker: #262626;
$gray-dark: #2d2d2d;
$gray: #383838;
$gray-light: #444444;
$gray-lighter: #5c5c5c;

$gray-rangoon: #1a1a1a;

$brand-white: white;

$brand-primary: #2c97de;
$brand-success: #84b547;
$brand-info: #2dbda8;
$brand-warning: #e76d3b;
$brand-danger: #cc3e4a;

$brand-cerulean: #00a3e3;
$brand-curious-blue: #2986c7;
$brand-endaveour: #0055a3;
$brand-minsk: #362d88;
$brand-eminence: #742787;
$brand-violet-eggplant: #aa1985;
$brand-mint-green: #72ff96;
$brand-aquamarine: #62ffe0;
$brand-malibu: #6abfff;
$brand-dodger-blue: #5e6cff;
$brand-heliotrope: #a26bff;
$brand-perfume: #f57ffe;

$brand-swamp: #222d33;
$brand-caper: #aeca89;
$brand-chelsea-cucumber: #88b152;
$brand-green-darker: #2b2f26;

$brand-facebook: #3a5c96;
$brand-twitter: #5fabdc;
$brand-foursquare: #f84978;
$brand-lastfm: #e2132f;
$brand-linkedin: #027ab4;
$brand-medium: #18aa6e;
$brand-windows: #10bff2;
$brand-amazon: orange;

//== Scanfolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: #212121;
//** Global text color on `<body>`.
$text-color: $gray-lighter;

//** Global textual link color.
$link-color: $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:
  "DarkerGrotesque",
  -apple-system,
  "Helvetica Neue",
  Helvetica,
  "Segoe UI",
  Arial,
  sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 13px;
$font-size-large: ceil(($font-size-base * 1.05)); // ~18px
$font-size-small: ceil(($font-size-base * 0.75)); // ~12px

$font-size-h1: floor(($font-size-base * 2.2)); // ~36px
$font-size-h2: floor(($font-size-base * 1.9)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.5)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.2)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.528571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.6;
$headings-color: white;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "/assets/vendor/fonts/";
//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

$border-radius-base: 2px;
$border-radius-large: 4px;
$border-radius-small: 1px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: #242424;
//** Background color used for `.table-hover`.
$table-bg-hover: #262626;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: $gray-dark;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal;

$btn-default-color: white;
$btn-default-bg: #212121;
$btn-default-border: #404040;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

$btn-link-disabled-color: $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;

//== Forms
//
//##

//** `<input>` background color
$input-bg: darken(#404040, 4.5%);
//** `<input disabled>` background color
$input-bg-disabled: transparent;

//** Text color for `<input>`s
$input-color: white;
//** `<input>` border color
$input-border: #404040;

//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: white;
$input-bg-focus: white; // Custom
$input-font-color-focus: $gray-darker; // Custom

//** Placeholder text color
$input-color-placeholder: #5c5c5c;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large: (
  ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
    2
);
//** Small `.form-control` height
$input-height-small: (
  floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
    2
);

//** `.form-group` margin
$form-group-margin-bottom: 15px;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: transparent;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #1a1a1a;
//** Dropdown menu `border-color`.
$dropdown-border: $gray-darker;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: $dropdown-border;

//** Dropdown link text color.
$dropdown-link-color: white;
//** Hover color for dropdown links.
$dropdown-link-hover-color: white;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $brand-primary;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $brand-primary;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: #959595;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width * 0.5));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) * 0.5);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%);
$navbar-inverse-bg: #1a1a1a;
$navbar-inverse-border: #262626;

// Inverted navbar links
$navbar-inverse-link-color: $gray-light;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: #232323;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: $navbar-inverse-link-hover-bg;
$navbar-inverse-link-disabled-color: #525252;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;

//=== PRIMARY navbar
// Reset primary navbar basics
$navbar-primary-color: lighten($gray-light, 15%);
$navbar-primary-bg: $brand-primary;
$navbar-primary-border: $brand-primary;

// Inverted navbar links
$navbar-primary-link-color: rgba(255, 255, 255, 0.6);
$navbar-primary-link-hover-color: #fff;
$navbar-primary-link-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-primary-link-active-color: $navbar-primary-link-hover-color;
$navbar-primary-link-active-bg: $navbar-primary-link-hover-bg;
$navbar-primary-link-disabled-color: #525252;
$navbar-primary-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-primary-brand-color: $navbar-primary-link-color;
$navbar-primary-brand-hover-color: #fff;
$navbar-primary-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-primary-toggle-hover-bg: rgba(255, 255, 255, 0.2);
$navbar-primary-toggle-icon-bar-bg: #fff;
$navbar-primary-toggle-border-color: rgba(255, 255, 255, 0.3);

//=== INFO navbar
// Reset info navbar basics
$navbar-info-color: lighten($gray-light, 15%);
$navbar-info-bg: $brand-info;
$navbar-info-border: $brand-info;

// Inverted navbar links
$navbar-info-link-color: rgba(255, 255, 255, 0.6);
$navbar-info-link-hover-color: #fff;
$navbar-info-link-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-info-link-active-color: $navbar-info-link-hover-color;
$navbar-info-link-active-bg: $navbar-info-link-hover-bg;
$navbar-info-link-disabled-color: #525252;
$navbar-info-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-info-brand-color: $navbar-info-link-color;
$navbar-info-brand-hover-color: #fff;
$navbar-info-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-info-toggle-hover-bg: rgba(255, 255, 255, 0.2);
$navbar-info-toggle-icon-bar-bg: #fff;
$navbar-info-toggle-border-color: rgba(255, 255, 255, 0.3);

//=== SUCCESS navbar
// Reset success navbar basics
$navbar-success-color: lighten($gray-light, 15%);
$navbar-success-bg: $brand-success;
$navbar-success-border: $brand-success;

// Inverted navbar links
$navbar-success-link-color: rgba(255, 255, 255, 0.6);
$navbar-success-link-hover-color: #fff;
$navbar-success-link-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-success-link-active-color: $navbar-success-link-hover-color;
$navbar-success-link-active-bg: $navbar-success-link-hover-bg;
$navbar-success-link-disabled-color: #525252;
$navbar-success-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-success-brand-color: $navbar-success-link-color;
$navbar-success-brand-hover-color: #fff;
$navbar-success-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-success-toggle-hover-bg: rgba(255, 255, 255, 0.2);
$navbar-success-toggle-icon-bar-bg: #fff;
$navbar-success-toggle-border-color: rgba(255, 255, 255, 0.3);

//=== WARNING navbar
// Reset warning navbar basics
$navbar-warning-color: lighten($gray-light, 15%);
$navbar-warning-bg: $brand-warning;
$navbar-warning-border: $brand-warning;

// Warning navbar links
$navbar-warning-link-color: rgba(255, 255, 255, 0.6);
$navbar-warning-link-hover-color: #fff;
$navbar-warning-link-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-warning-link-active-color: $navbar-warning-link-hover-color;
$navbar-warning-link-active-bg: $navbar-warning-link-hover-bg;
$navbar-warning-link-disabled-color: #525252;
$navbar-warning-link-disabled-bg: transparent;

// Warning navbar brand label
$navbar-warning-brand-color: $navbar-warning-link-color;
$navbar-warning-brand-hover-color: #fff;
$navbar-warning-brand-hover-bg: transparent;

// Warning navbar toggle
$navbar-warning-toggle-hover-bg: rgba(255, 255, 255, 0.2);
$navbar-warning-toggle-icon-bar-bg: #fff;
$navbar-warning-toggle-border-color: rgba(255, 255, 255, 0.3);

//=== DANGER navbar
// Reset danger navbar basics
$navbar-danger-color: lighten($gray-light, 15%);
$navbar-danger-bg: $brand-danger;
$navbar-danger-border: $brand-danger;

// danger navbar links
$navbar-danger-link-color: rgba(255, 255, 255, 0.6);
$navbar-danger-link-hover-color: #fff;
$navbar-danger-link-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-danger-link-active-color: $navbar-danger-link-hover-color;
$navbar-danger-link-active-bg: $navbar-danger-link-hover-bg;
$navbar-danger-link-disabled-color: #525252;
$navbar-danger-link-disabled-bg: transparent;

// danger navbar brand label
$navbar-danger-brand-color: $navbar-danger-link-color;
$navbar-danger-brand-hover-color: #fff;
$navbar-danger-brand-hover-bg: transparent;

// danger navbar toggle
$navbar-danger-toggle-hover-bg: rgba(255, 255, 255, 0.2);
$navbar-danger-toggle-icon-bar-bg: #fff;
$navbar-danger-toggle-border-color: rgba(255, 255, 255, 0.3);

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: #252525;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-tabs-border-color: #383838;

$nav-tabs-link-hover-border-color: #383838;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: white;
$nav-tabs-active-link-hover-border-color: #383838;

$nav-tabs-justified-link-border-color: $nav-tabs-border-color;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

//== Pagination
//
//##

$pagination-color: $link-color;
$pagination-bg: transparent;
$pagination-border: #5c5c5c;

$pagination-hover-color: white;
$pagination-hover-bg: transparent;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $text-muted;
$pagination-disabled-bg: transparent;
$pagination-disabled-border: $pagination-border;

//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: #383838;
$jumbotron-heading-color: white;
$jumbotron-font-size: ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5));

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: $gray-darker;
$state-success-bg: #deebcd;
$state-success-border: transparent;

$state-info-text: $gray-darker;
$state-info-bg: #bddef5;
$state-info-border: transparent;

$state-warning-text: $gray-darker;
$state-warning-bg: #f9d7bb;
$state-warning-border: transparent;

$state-danger-text: $gray-darker;
$state-danger-bg: #f1c3c6;
$state-danger-border: transparent;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #444444;
$tooltip-opacity: 0.9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: white;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: $gray-darker;
//** Popover fallback border color
$popover-fallback-border-color: white;

//** Popover title background color
$popover-title-bg: $gray-dark;

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: white;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken(
  $popover-fallback-border-color,
  20%
);

//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $brand-primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: #fff;
//** Default text color of a linked label
$label-link-hover-color: #fff;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: #fff;
//** Modal content border color
$modal-content-border-color: $brand-primary;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

//** Modal backdrop background color
$modal-backdrop-bg: #ccc;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.9;
//** Modal header border color
$modal-header-border-color: $gray-darker;
//** Modal footer border color
$modal-footer-border-color: $gray-darker;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #5c5c5c;
//** Progress bar text color
$progress-bar-color: #fff;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

//** Default progress bar color
$progress-bar-bg: $brand-primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;
//** White progress bar color
$progress-bar-white-bg: $brand-white;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: $navbar-inverse-bg;
//** `.list-group-item` border color
$list-group-border: $gray-darker;
//** List group border radius
$list-group-border-radius: $border-radius-base;

//** Background color of single list items on hover
$list-group-hover-bg: $gray-darker;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

//** Text color of disabled list items
$list-group-disabled-color: $text-muted;
//** Background color of disabled list items
$list-group-disabled-bg: #212121;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: $text-color;
$list-group-link-hover-color: white;
$list-group-link-heading-color: white;

//== Panels
//
//##

$panel-bg: $navbar-inverse-bg;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: 3px;

//** Border color for elements within panels
$panel-inner-border: $gray-darker;
$panel-footer-bg: #282828;

$panel-default-text: #1797e1;
$panel-default-border: $gray-darker;
$panel-default-heading-bg: transparent;

$panel-primary-text: #1797e1;
$panel-primary-border: $panel-default-border;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: $state-success-text;
$panel-success-border: $panel-default-border;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $panel-default-border;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $panel-default-border;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $panel-default-border;
$panel-danger-heading-bg: $state-danger-bg;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 4px;
//** Thumbnail background color
$thumbnail-bg: $gray-base;
//** Thumbnail border color
$thumbnail-border: $gray-darker;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;

//== Wells
//
//##

$well-bg: $thumbnail-bg;
$well-border: darken($well-bg, 7%);

//== Badges
//
//##

$badge-color: #fff;
//** Linked badge text color on hover
$badge-link-hover-color: #fff;
$badge-bg: $gray-light;

//** Badge text color in active nav link
$badge-active-color: $link-color;
//** Badge background color in active nav link
$badge-active-bg: #fff;

$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: #151515;
//** Breadcrumb text color
$breadcrumb-color: $gray-light;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-lighter;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/";

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;

//== Close
//
//##

$close-font-weight: bold;
$close-color: white;
$close-text-shadow: none;

//== Code
//
//##

$code-color: $gray-darker;
$code-bg: #00a3e3;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #1a1a1a;
$pre-color: $gray-lighter;
$pre-border-color: transparent;
$pre-scrollable-max-height: 340px;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;

//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: #444444;
//** Page header border color
$page-header-border-color: $hr-border;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;

$dl-horizontal-breakpoint: $grid-float-breakpoint;
