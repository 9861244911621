@import "../../../styles/theme/app/variables";

.secondaryTitle {
  color: #000;
  font-weight: 700;
}

.newField {
  width: 50%;
  height: 100%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.note {
  font-size: 14px;
  color: green;
}

.imgSizeError {
  color: red;
  position: relative;
  text-align: c;
  top: 33px;
  width: 300px;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.generatebutton {
  margin-left: 0px;
  margin-top: 0;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #630bde !important;
  border-radius: 4px !important;
  padding: 18px !important;
}
.updatee {
  color: #be323e;
}
.clearButton {
  padding: 15px !important;
  color: #630bde !important;
  border-color: #630bde !important;
  font-size: 16px !important;
  border-radius: 6px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #630bde !important;
  }
}

.new h3 {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  left: 14px !important;
  color: #000f20 !important;
  font-size: 24px !important;
  font-weight: bold;
  margin-bottom: 33px !important;
  margin-left: 14px !important;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    font-size: 16px;
    height: 42px;
    padding: 0 14px;
    transition: all ease-out 300ms;
    text-transform: capitalize;
    border-radius: 4px;
  }

  .saveButton {
    background-color: $main-btn-clr;
    color: #fff;
    border-color: $main-btn-clr;

    &:disabled {
      background-color: $main-btn-clr-disabled;
      cursor: not-allowed;
    }

    &:hover {
      background-color: $main-btn-clr-hvr;
      color: #fff;
    }
  }

  .cancelButton {
    background-color: #fff;
    color: $main-btn-clr;
    border-color: $main-btn-clr;

    &:hover {
      background-color: $main-btn-clr;
      color: #fff;
    }
  }
}

.editButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  height: 42px;
  padding: 0 14px;
  transition: all ease-out 300ms;
  text-transform: capitalize;
  border-radius: 4px;
  background-color: #ffffff;
  color: $main-btn-clr;
  border-color: $main-btn-clr;
  border: 1px solid $main-btn-clr;

  &:disabled {
    background-color: $main-btn-clr-disabled;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $main-btn-clr;
    color: #fff;
  }
}

.regenerateButton {
  background-color: #630bde !important;
  color: #fff !important;
  font-size: 16px;
  border-color: #630bde !important;
  border-radius: 4px;
  min-width: 105px;
}

.populateButton {
  padding: 15px !important;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 6px;
  margin-right: 10px !important;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff !important;
  }
}

.projectIcon {
  margin-bottom: 35px;
}

.savebutton {
  float: right;
  width: 75px;
  height: 30px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.alert {
  position: fixed;
  top: 33.5%;
  left: 44%;
  width: 23.5%;
  background-color: #013874;
  color: #ffffff !important;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0%;
  }
  button {
    opacity: 1 !important;
  }
}
.Alertbutton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  margin: 10px;
}
.Alertbutton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}

.ConfirmButton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  float: right;
}
.ConfirmButton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}
.deletebtn {
  color: #cc3e4a;
  background-color: #fff;
  border-color: #be323e;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.52857;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CancelButton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  float: left;
}
.CancelButton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}

.settingContainerSty {
  margin-bottom: 80px;
}

.cancelButtonEdit {
  background-color: mediumseagreen !important;
  color: #fff !important;
  font-size: 16px;
  border-color: #630bde !important;
  padding: 7px;
  border-radius: 4px;
  position: relative;
  height: 48px;
  right: 19px !important;
  min-width: 105px;
  bottom: 16px !important;
}

.newDeleteButton {
  padding: 5px 15px !important;
  color: #630bde !important;
  border-color: #630bde !important;
  font-size: 16px !important;
  border-radius: 6px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #630bde !important;
  }
}

.new-btn-settings {
  padding: 10px 30px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  font-weight: 700;
  font-size: 14px;
}

.tooltip {
  color: #4ea0ed;
  position: relative;
  display: inline-block;
  border-radius: 6px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 200px;
  bottom: -2px;
  left: 18px;
  background-color: #4ea0ed;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltiptext {
  visibility: visible;
}
.tooltip:hover {
  visibility: visible;
  color: #1c4f7e;
}

.inputField {
  width: 220px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 5px;
}
.inputField1 {
  margin-right: 4px;
}
.trashIcon {
  margin-left: 4px;
  top: 2px;
  font-size: 24px;
}
