@import "../../../../styles/theme/app/variables.scss";

.projectInfo {
  max-width: 600px;
  font-family: $main-dashboard-font;
  padding: 0 20px;

  .secondaryTitle {
    color: #000;
    font-weight: 700;
  }

  .websiteDescriptionInput,
  .websiteInput,
  .storNameInput {
    width: 300px;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 5px;
  }

  .logoInputContainer {
    width: 300px;
    margin-bottom: 51px;
  }

  .imgSizeError {
    color: red;
    position: relative;
    text-align: center;
    top: 33px;
    width: 300px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 30px 0;

    > button {
      border-radius: 5px;
      outline: none;
      font-weight: 700;
      font-size: 16px;
      padding: 0 14px;
      transition: all ease-out 300ms;
      height: 42px;
      text-transform: capitalize;

      &:disabled {
        background-color: $main-btn-clr-disabled;
        cursor: not-allowed;
      }
    }

    .editBtn {
      border: 1px solid $main-btn-clr;
      color: $main-btn-clr;
      background-color: #fff;

      &:hover {
        background-color: $main-btn-clr;
        color: #fff;
      }
    }

    .deleteBtn {
      border: 1px solid $main-delete-clr;
      background-color: $main-delete-clr;
      color: #fff;

      &:hover {
        background-color: $main-delete-clr-hvr;
      }
    }

    .saveBtn {
      background-color: $main-btn-clr;
      border: 1px solid $main-btn-clr;
      color: #fff;

      &:hover {
        background-color: $main-btn-clr-hvr;
      }
    }
  }
}
