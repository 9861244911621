@import "../../styles/theme/app/variables.scss";

.menuContainer {
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  min-width: 185px;
  border-radius: 5px;
  border: 1px solid #7676761a !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  max-height: 268px;
  overflow-y: auto;
  font-family: $main-dashboard-font;

  & > p:first-of-type {
    color: #5c5c5c !important;
    font-weight: bold;
    font-size: 12px;
    padding: 13px 16px 5px 16px;
    border-bottom: 1px solid #e7e7e7;
    margin: 0;
  }

  & > p:not(:first-of-type) {
    color: #5c5c5c !important;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    text-align: left !important;
    padding: 5px 16px 5px 16px;
    margin: 0;
    text-decoration: underline;
    border-top: 1px solid #e7e7e7;
    transition: color 200ms;
    text-align: center !important;

    &:hover {
      color: #464646 !important;
    }
  }

  & > div {
    padding: 8px 16px;
    font-weight: bold !important;
    font-size: 16px;
    color: #9a9a9a;
  }

  .menuHeader {
    background-color: #7676761a;
    font-size: 10px;
    color: #767676bf;
  }

  .menuRow {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: normal;
    color: #767676;
    text-transform: capitalize;
    margin: 6px 0;
  }

  @keyframes datatable_menu {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 300px;
    }
  }
}
