.border {
  border-top: 1px ridge white !important;
  display: block !important;
  width: 100%;
}
.dashboardVer {
  text-decoration: none;
}
.dashboardVer :hover {
  background: #ffff !important;
}
.sidebarScroll {
  height: 100%;
}

.sidebarMain {
  transform: translate(0, 0);
}

.sidebarFixed {
  max-height: none !important;
  color: #615f72;
  background-color: #ffff !important;
  &:not(.fullHeight) .sidebarScroll {
    max-height: calc(100vh - 50px);
  }

  &.affixed .sidebarScroll,
  &.fullHeight .sidebarScroll {
    max-height: 100vh;
    background-color: #ffff;
    color: #615f72;
  }
  //  &.fullHeight .sidebarScroll:hover {
  //     background: #4766ff  !important;
  // }
}

.sidebarLink {
  // display: flex !important;
  align-items: center;
  outline: none !important;
  display: flex !important;
  text-transform: capitalize !important;
}

.containerLink {
  outline: none !important;
}

.sidebarLinkText {
  display: inline-flex;
  justify-content: space-between;
  flex-grow: 1;
}

.sidebarClass {
  background-color: white !important;
  color: #615f72;
}

// .arrows{
//     transform: rotateZ(180deg) !important;
// }
