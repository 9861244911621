@import "../../../styles/theme/app/variables.scss";

.label {
  color: $main-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 654px;

  .forgotPasswordClick {
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    border: none;
    outline: none;
    color: $main-btn-clr;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 0;
    transition: all 0.3s ease-in-out;
    margin-left: auto;

    &:hover {
      color: $main-btn-clr-hvr;
    }

    &.rtl {
      margin-left: unset;
      margin-right: auto;
    }
  }
}

.right-input-hint {
  color: #630bde !important;
  position: absolute;
  right: 5%;
  font-size: 20px;
  font-weight: 500;
  top: 50%;
  transform: translateY(-50%);

  &.rtl {
    right: unset;
    left: 5%;
  }
}

.input {
  height: $main-input-height;
  color: $main-input-clr;
  font-size: $main-input-font-size;
  border-radius: $main-input-border-radius !important;
  font-weight: $main-input-font-weight;
  border: 1px solid $main-input-border-clr;
  background-color: $main-input-background-clr;
  padding: $main-input-padding 16px;
  width: 100%;
  outline: none;
  transition: all $main-input-transition-duration
    $main-input-transition-timing-function;
  max-width: 344px;

  &:focus:not(:disabled) {
    border-color: $main-input-border-clr-focus;
    background-color: $main-input-background-clr-focus;
    color: $main-input-clr-focus;
  }

  &:hover:not(:disabled) {
    border: 1px solid $main-input-border-clr-hvr !important;
  }

  &:disabled {
    background-color: $main-input-background-clr-disabled;
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr;

    // disable the hover effect
    &:hover {
      border: 1px solid $main-input-border-clr !important;
      box-shadow: none !important;
    }
  }

  &::placeholder {
    color: $main-input-placeholder-clr;
    font-size: $main-input-placeholder-font-size;
    font-weight: $main-input-placeholder-font-weight;
  }

  &.rtl {
    direction: rtl;
  }
}

.maxRecommended {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  top: 110%;
  right: 2px;

  &.rtl {
    right: unset;
    left: 2px;
  }
}

.maxRecommendedReached {
  color: #dc3545;
}

.hint {
  margin-top: 8px;
  font-size: 13px;
  color: #9a9a9a;
  font-weight: bold;

  > span {
    color: $main-font-clr;
  }
}

.error {
  font-size: 13px;
  color: $error-text-clr;
}
