@import "../../styles/theme/app/variables";

.panelNoBg {
  border: 1px solid $agio-grey-lighter !important;
  background: $body-bg !important;

  > div:first-of-type {
    background-color: transparent;
  }
}

.panelBgLight {
  background-color: $agio-grey-lightest;
}

.panelBgGray {
  background-color: $agio-grey-lighter;
}

.flexHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullBody {
  padding: 0;
  transition: max-height 0.2s ease-in-out;
}

.fullFooter {
  padding: 0;
}

.titleReset {
  font-size: inherit !important;
}
