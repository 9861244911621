@import "../../styles/theme/app/variables.scss";

.emptyStatePage {
  --font-clr: rgba(18, 18, 18, 1);
  --font-clr-secondary: rgba(113, 113, 113, 1);

  width: 100%;
  min-height: calc(100vh - 201px);
  padding: 24px;

  .pageTitle {
    font-size: 20px;
    font-weight: 700;
    color: var(--font-clr);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      object-fit: contain;
      margin-bottom: 16px;
    }

    .title {
      font-weight: 600;
      font-size: 26px;
      color: var(--font-clr);
      margin-bottom: 24px;
      text-align: center;
    }

    .subTitle {
      font-weight: 500;
      font-size: 18px;
      color: var(--font-clr-secondary);
      margin-bottom: 24px;
      text-align: center;
      max-width: 500px;
    }

    .CTAButton {
      border-radius: 5px;
      background-color: $main-btn-clr;
      color: #fff;
      padding: 0 24px;
      height: 42px;
      font-weight: 500;
      font-size: 18px;
      outline: none;
      border: 1px solid $main-btn-clr;
      transition: all ease-out 300ms;

      &:hover {
        background-color: $main-btn-clr-hvr;
      }
    }
  }
}
