@import "../../styles/theme/app/variables.scss";

.outline {
  &:not(:hover) {
    background: transparent !important;
  }
  &:hover {
    color: white !important;
  }
}

.buttonStructure {
  border-radius: 5px;
  border-color: $main-purple-clr;
  border-style: solid;
  height: 42px;

  color: white;
  background-color: $main-purple-clr;
}
