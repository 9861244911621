@import "../../../styles/theme/app/variables";

.fileUploadInput {
  font-family: $main-dashboard-font;

  .label {
    color: #222;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
  }

  .inputContainer {
    width: 100%;
    position: relative;
  }

  .readOnlyInput {
    text-overflow: ellipsis;
    overflow: hidden;
    height: $main-input-height;
    width: 100%;
    padding: $main-input-padding;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    font-weight: $main-input-font-weight;
    font-size: $main-input-font-size;
    color: $main-input-clr;
    background-color: $main-input-background-clr;
  }

  .uploadBtn {
    background-color: transparent;
    font-size: 24px;
    padding: 0 15px;
    position: absolute;
    right: 0;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $main-btn-clr;
    }

    &.rtl {
      left: 5px;
      right: unset;
    }
  }

  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    height: $main-input-height;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .hintText {
    font-weight: 700;
    font-size: 15px;
    color: $secondary-tex-clr;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .imgSizeError {
    color: $main-delete-clr;
    font-size: 15px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
