@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
