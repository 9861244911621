@import "../../styles/theme/app/variables.scss";

.dateFilter {
  position: relative;

  .dayPickersContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .dateFilterButton {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $main-dashboard-font;
    font-weight: 600;
    outline: none;
    gap: 8px;
    border-radius: 4px;
    transition: all ease-in-out 300ms;
    text-transform: capitalize;
    color: $main-purple-clr;
    background-color: #fff;
    border: 1px solid rgba(99, 11, 222, 0.3);
    font-size: 16px;
    font-weight: 600;
    padding: 12px 24px;

    &:focus:enabled,
    &:hover:enabled {
      background-color: $main-purple-clr;
      color: #fff;
    }

    &:disabled {
      background-color: rgba(99, 11, 222, 0.25);
      color: rgba(41, 41, 41, 0.5);
      border: 1px solid rgba(99, 11, 222, 0.3);
    }

    .icon {
      font-size: 20px;
    }
  }

  .dateFilterMenu {
    position: absolute;
    transition: all 300ms ease-out;
    transform-origin: top;
    transform: scaleY(0);
    z-index: 5;
    background-color: #fff;
    padding: 12px;
    width: 300px;
    top: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    right: 0;

    &.menuOpen {
      animation-name: unfoldMenu;
      animation-timing-function: ease-out;
      animation-duration: 300ms;
      animation-iteration-count: 1;
      transform: scaleY(1);

      &.noAnimation {
        animation-duration: 0ms;
      }
    }

    &.menuClosed {
      animation-name: foldMenu;
      animation-timing-function: ease-out;
      animation-duration: 300ms;
      animation-iteration-count: 1;
      transform: scaleY(0);

      &.noAnimation {
        animation-duration: 0ms;
      }
    }

    &.rtl {
      right: unset;
      left: 0;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .dateButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    margin: 12px 0;

    > button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $main-dashboard-font;
      font-weight: 600;
      outline: none;
      gap: 8px;
      border-radius: 4px;
      transition: all ease-in-out 300ms;
      text-transform: capitalize;
      color: $main-purple-clr;
      background-color: #fff;
      border: 1px solid rgba(99, 11, 222, 0.3);
      font-size: 16px;
      font-weight: 600;
      padding: 12px 24px;

      &.activeBtn,
      &:focus:enabled,
      &:hover:enabled {
        background-color: $main-purple-clr;
        color: #fff;
      }

      &:disabled {
        background-color: rgba(99, 11, 222, 0.25);
        color: rgba(41, 41, 41, 0.5);
        border: 1px solid rgba(99, 11, 222, 0.3);
      }

      .icon {
        font-size: 20px;
      }
    }
  }

  .actionsButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      border-radius: 5px;
      border: 1px solid $main-purple-clr;
      height: 42px;
      padding: 0 14px !important;
      transition: all ease-out 300ms;
      font-weight: 700;
      font-size: 16px;
      transition: all ease-out 300ms;
    }

    .cancelBtn {
      background-color: #fff;
      color: $main-purple-clr;

      &:hover {
        background-color: $main-purple-clr;
        color: #fff;
      }
    }

    .applyDateBtn {
      background-color: $main-purple-clr;
      color: #fff;

      &:hover {
        background-color: $main-btn-clr-hvr;
      }
    }
  }

  @keyframes unfoldMenu {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }

  @keyframes foldMenu {
    0% {
      transform: scaleY(1);
    }

    100% {
      transform: scaleY(0);
    }
  }
}

.skeleton-date-filter {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
