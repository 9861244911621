@import "../../styles/theme/app/variables.scss";

.dataTable {
  .dataTableHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    flex-direction: row-reverse;

    button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $main-dashboard-font;
      font-weight: 600;
      outline: none;
      gap: 8px;
      border-radius: 4px;
      transition: all ease-in-out 300ms;
      text-transform: capitalize;
      color: $main-purple-clr;
      background-color: #fff;
      border: 1px solid rgba(99, 11, 222, 0.3);
      font-size: 16px;
      font-weight: 600;
      padding: 12px 24px;

      &:focus:enabled,
      &:hover:enabled {
        background-color: $main-purple-clr;
        color: #fff;
      }

      &:disabled {
        background-color: rgba(99, 11, 222, 0.25);
        color: rgba(41, 41, 41, 0.5);
        border: 1px solid rgba(99, 11, 222, 0.3);
      }

      .icon {
        font-size: 20px;
      }
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .multiActionBtnContainer {
      margin-right: auto;
      position: relative;

      .multiActionBtn {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        font-weight: normal;
        background-color: $main-btn-clr;
        border: 1px solid $main-btn-clr;
        color: white;
        border-radius: 4px;
        padding: 0 24px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        outline: none;

        .count {
          font-weight: bold;
          font-size: 13px;
          color: white;
          background-color: #ffffff4d;
          border-radius: 25px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .multiActionBtn:focus,
      .multiActionBtn:hover {
        background-color: $main-btn-clr-hvr;
      }
    }
  }

  .gearBtnContainer {
    position: relative;

    // .gearBtn {
    //   color: $main-btn-clr;
    //   background-color: white;
    //   border: 1px solid $main-btn-clr;
    //   font-size: $main-button-font-size !important;
    //   font-weight: 500;
    //   padding: 0 14px;
    // }

    // .gearBtn:focus,
    // .gearBtn:hover {
    //   background-color: $main-btn-clr-hvr;
    //   color: white;
    // }
  }

  .exportBtnContainer {
    // .exportBtn {
    //   padding: 0 18px;
    //   color: $main-btn-clr;
    //   border: 1px solid $main-btn-clr;
    //   background-color: white;
    //   font-size: $main-button-font-size !important;
    //   font-weight: 500;
    // }

    // .exportBtn:focus:enabled,
    // .exportBtn:hover:enabled {
    //   background-color: $main-btn-clr-hvr;
    //   color: white;
    // }
  }

  .importBtnContainer {
    // .importBtn {
    //   width: 123px;
    //   color: $main-btn-clr;
    //   border: 1px solid $main-btn-clr;
    //   background-color: white;
    //   font-size: $main-button-font-size !important;
    //   font-weight: 500;
    // }

    // .importBtn:focus,
    // .importBtn:hover {
    //   background-color: $main-btn-clr-hvr;
    //   color: white;
    // }
  }

  .dataTableContainer {
    max-height: calc(100vh - 375px);
    margin: 16px 24px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Set the background color of the scroll bar */
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    /* Set the color and style of the scroll bar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: #8888881c;
      border-radius: 5px;
      transition: all ease-in-out 150ms;
    }

    /* Set the color and style of the scroll bar thumb when hovering over it */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    /* Set the width and height of the scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &:hover::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    .table {
      --table-cell-padding: 14px 24px;
      width: 100%;
      border-spacing: 0 8px;
      border-collapse: separate;

      .tableHead {
        background-color: $table-header-bg;

        .tableHeadDataContainer {
          display: flex;
          align-items: center;
          gap: 6px;

          > span {
            white-space: pre;
          }
        }

        &.rtl {
          > tr {
            th {
              text-align: right;
              direction: rtl;
            }
          }
        }

        > tr {
          > th {
            font-family: $main-dashboard-font;
            color: $table-header-text-clr;
            font-weight: 800;
            font-size: 18px;
            text-transform: uppercase;
            padding: var(--table-cell-padding);
          }

          > th:last-child {
            text-align: center;
          }
        }
      }

      .tableBody {
        > tr {
          background-color: $table-row-bg;
          height: 70px;

          > td {
            font-family: $main-dashboard-font;
            padding: var(--table-cell-padding);
            color: $table-row-text-clr;
            font-size: 16px;
            font-weight: 700;
            text-overflow: ellipsis;
            vertical-align: middle;
          }
        }

        tr::after {
          content: "\200C";
          display: block;
          line-height: 6px;
          background-color: #f9fafc;
        }

        .urlTableCell {
          background-color: rgba(242, 242, 242, 1);
          padding: 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(124, 140, 155, 1);
          i {
            color: rgba(242, 46, 82, 1) !important;
          }
        }
      }
    }
  }

  .rotate-icon-90 {
    transform: rotate(-90deg);
    color: #5c5c5c;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    display: initial;
  }

  .imageTableCell {
    width: 46px;
    height: 46px;
    object-fit: contain;
  }
}

.download-icon {
  vertical-align: middle;
  height: 100%;
}

.copy-icon {
  i {
    color: #9a9a9a !important;
  }
}

.mobile-preview {
  font-size: 3rem !important;
  cursor: pointer !important;
  filter: brightness(100%) !important;
  transition: all 0.5s !important;
  &:hover {
    filter: brightness(70%) !important;
  }
}

// loaders

.skeletonized-btn-gear {
  width: 185px;
  height: 48px;
  border-radius: 5px;
}

.skeletonized-btn-csv {
  width: 123px;
  height: 48px;
  border-radius: 5px;
}

.skeletonized-btn-upload {
  width: 123px;
  height: 48px;
  border-radius: 5px;
}

.skeletonized-header-label {
  width: 100%;
  height: 60px;
}

.skeleton-animation {
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.more-info-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 1px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background-color: #bbdbfb !important;
  color: #1d86f1 !important;
}

.extraDetails {
  padding: 1rem;
  color: #000;

  p {
    margin: 0;
  }

  > div {
    display: grid;
    grid-template-columns: 140px auto;
    gap: 1rem;
    align-items: center;
    border-bottom: 1px solid #eee;

    > p {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
    }

    > p:nth-child(2) {
      word-break: break-all;
    }
  }

  > div:last-child {
    border: none;
  }
}
