@import "../../styles/theme/app/variables.scss";

.label {
  color: $label-text-clr;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}
