@import "../../styles/theme/app/variables.scss";

.checkbox {
  .label {
    color: $label-text-clr;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
}
