@import "../../../../../styles/theme/app/variables";

.column {
  margin-left: 2%;
  margin-right: 2%;
}

.contentbody {
  margin: 20px 20px;
}

.button {
  width: 10%;
  float: right;
  height: 5%;
  margin-bottom: 3%;

  @media only screen and (max-width: 400px) {
    width: 100px;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);

  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.advancedbtn {
  width: 150px;
  margin: 20px 0px;
  float: right;
  margin-right: 20px;
}

.configbutton {
  width: 70px !important;
  margin-left: 30%;
}

.configbuttontext {
  margin-left: 10px;
}

.submit {
  float: right;
}

.cancelbtn {
  width: 70px;
}

.Form {
  width: 40%;

  @media only screen and (max-width: 860px) {
    width: 100%;
  }
}

.alert {
  background-color: #013874 !important;
  color: #ffffff !important;
  position: absolute;
  top: 0%;
  left: 30%;
  width: 30%;
  z-index: 5;

  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}

.pointer {
  cursor: pointer;
}

.grecePeriod {
  font-size: 14px;
  font-weight: 500;
  color: black;
  background-color: #ffffff;
  margin-left: 10px;
}

.radioo {
  margin-right: 10px;
  font-size: 14px;
}

/////////////////
.contentbody {
  margin-top: 20px;
}

.contain {
  button {
    border: 5px;
    background-color: $main-purple-clr;
    border-color: $main-purple-clr;
    background-color: $main-purple-clr;
    color: white;
    height: 42px;
    padding: 0 12px;
  }

  position: relative;

  @media only screen and (max-width: 415px) {
    width: 100%;
    margin-top: 5%;
  }

  .titleButton {
    position: absolute;
    top: -40px;
    right: 0;
    height: 30px;
    padding: 0 20px;
    background: $agio-green-blue;
    line-height: 30px;
    color: $agio-white;
    border: none;
  }
}

.helpButton {
  border-radius: 8px;
  background: #fff1a7;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin: 12px auto;

  img {
    width: 32px;
    height: 32px;
  }

  p {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  button {
    background-color: unset;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: #630bde;
    text-decoration: underline;
  }
  svg {
    transform: rotateY(180deg);
  }
}
