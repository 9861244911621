@import "../../styles/theme/app/variables.scss";

.lockedPage {
  padding: 24px;

  .breadcrumb {
    font-weight: 700;
    font-size: 20px;
    color: $main-text-clr;
  }

  .container {
    margin-top: 11px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;

    .content {
      grid-column: 1/ 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 32px;

      .title {
        font-weight: 700;
        font-size: 24px;
        color: $main-text-clr;
        margin-bottom: 16px;
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        color: $secondary-tex-clr;
        margin-bottom: 24px;
      }

      .unlockButton {
        padding: 0 24px;
        font-weight: 700;
      }
    }

    .imageContainer {
      grid-column: 2/ 3;

      > img {
        width: 100%;
      }
    }
  }
}
