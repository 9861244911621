@import "../../../styles/theme/app/variables";

.container {
  width: 400px;
  height: 770px;
  margin: 0 auto;
  padding: 96px 38.5px;
  position: relative;
  .deviceImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 415px) {
      width: 88%;
    }
  }
  .LandingPageReviewContain {
    height: 578px;
    position: relative;
    .preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      @media only screen and (max-width: 415px) {
        width: 80%;
      }
    }
  }

  .AppPushPreviewContain {
    height: 599px;
    position: relative;
    top: -21px;
    left: -1px;
    width: calc(100% + 2px);
    .previewBg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      @media only screen and (max-width: 415px) {
        width: 88%;
      }
    }
    .previewArea {
      position: absolute;
      border-bottom: 1px $agio-white solid;
      border-top: 1px $agio-white solid;
      top: 220px;
      width: 100%;
      min-height: 6em;
      padding: 1em;
      color: $agio-white;
      overflow-y: scroll;
    }
  }
}
.smsCampaign {
  width: calc(95% + 2px) !important;
  left: 2px !important;
  margin: auto !important;
}
.previewAnalytics {
  border-top-right-radius: 8px;
  width: 94% !important;
  border-top-left-radius: 8px;
  margin: auto;
}

.newContainer {
  width: 400px;
  height: 680px;
  margin: 0 auto;
  padding: 96px 38.5px;
  position: relative;
  .deviceImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 415px) {
      width: 88%;
    }
  }
  .LandingPageReviewContain {
    height: 578px;
    width: 96%;
    margin: auto;
    position: relative;
    .preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      @media only screen and (max-width: 415px) {
        width: 80%;
      }
    }
  }
  .AppPushPreviewContain {
    height: 599px;
    position: relative;
    top: -21px;
    left: -1px;
    width: calc(100% + 2px);
    .previewBg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      @media only screen and (max-width: 415px) {
        width: 88%;
      }
    }
    .previewArea {
      position: absolute;
      border-bottom: 1px $agio-white solid;
      border-top: 1px $agio-white solid;
      top: 220px;
      width: 100%;
      min-height: 6em;
      padding: 1em;
      color: $agio-white;
      overflow-y: auto;
      word-break: break-all;
    }
  }
}
