@import "../../styles/theme/app/variables";

.navbar {
  border-radius: 0;
  border-color: white !important;
}

.navbar > * {
  position: relative;
}

.navbarCollapse {
  max-height: calc(100vh - 50px);
  background-color: white !important;
  border-radius: 0px !important;
  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
}
////////// resposive style
@media (max-width: 767px) {
  .collapse {
    display: inline !important;
  }
  .navbarCollapse {
    // display: block;
    // height: 0;
    display: contents !important;
    background-color: none !important;
  }
  .navbarCollapse::before {
    contain: none;
    display: none;
  }
  .navbarCollapse > ul {
    margin: 0;
    float: right;
  }
  .navbarCollapse > p {
    display: none !important;
  }
  .navbar {
    position: fixed !important;
  }
}
