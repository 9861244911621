@import "../../../styles/theme/app/variables";

.detailsList {
  font-family: $main-dashboard-font;

  .key {
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .value {
    font-weight: 700;
    font-size: 16px;
    color: $secondary-tex-clr;
    margin: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 765px) {
  .widthsize {
    display: block;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}
.divClass {
  position: absolute;
}
.descriptionSty {
  padding: 0;
  margin: 0;
  padding-bottom: 29px;
  position: relative;
  color: #7c8c9b;
  font-size: 14px;
  margin-top: 6px;
}

/* Tooltip text */
.tooltiptext {
  display: none;
  width: 100%;
  background-color: #272727 !important;
  color: #fff !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 1px;
  left: 49%;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  padding: 8px 21px;
}
.tooltip:hover {
  .tooltiptext {
    display: block;
  }
}
.tooltip:hover .tooltiptext {
  display: block;
}
