@import "../../styles/theme/app/variables.scss";

.inputContainer {
  position: relative;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
  transition: all $main-input-transition-timing-function
    $main-input-transition-duration;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    left: -15px;
    background-color: transparent;
  }

  &:has(> input.error) {
    &::before {
      background-color: $main-input-border-clr-error;
    }
  }

  > label {
    margin-bottom: 8px;
    color: $main-font-clr;
  }

  .textArea {
    resize: none;
    min-height: 200px;
  }

  > input,
  .textArea {
    width: 100%;
    padding: $main-input-padding;
    transition: all $main-input-transition-timing-function
      $main-input-transition-duration;
    border: 1px solid $main-input-border-clr;
    background-color: $main-input-background-clr;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    color: $main-font-clr;
    border-radius: $main-input-border-radius;
    outline: none;

    &:focus {
      border: 1px solid $main-input-border-clr-focus;
      background-color: $main-input-background-clr-focus;
    }

    &.error {
      border: 1px solid $main-input-border-clr-error;
      background-color: $main-input-bg-clr-error;
    }
  }

  > input {
    height: $main-input-height;
    padding-right: 40px;
  }

  &.rtl {
    > input {
      padding-right: $main-input-padding;
      padding-left: 40px;
    }
  }

  .textArea {
    height: 80px;
  }

  .helper {
    font-size: 13px;
    color: $helper-text-clr;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .errorsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .error {
      font-size: 13px;
      color: $error-text-clr;
      margin-top: 4px;
      margin-bottom: 0;
    }
  }

  .emojiButton {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 5px;
    top: 35px;
    transition: all $main-input-transition-timing-function 150ms;

    &:hover {
      transform: scale(1.1);
    }

    .icon {
      font-size: 25px;
    }
  }

  &.rtl {
    .emojiButton {
      right: auto;
      left: 5px;
    }
  }

  .emojiPickerContainer {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 80px;
  }

  &.rtl {
    .emojiPickerContainer {
      right: auto;
      left: 0;
    }
  }
}
