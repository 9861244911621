@import "../../../styles/theme/app/variables.scss";

.onboardingBtn {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $main-dashboard-font;
  outline: none;
  gap: 8px;
  border-radius: 4px;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid $main-purple-clr;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 12px 24px;
  background-color: $main-purple-clr;
  width: 85% !important;
  margin: auto;
  margin-top: 2em;
}

.onBoadringModal {
  font-family: $main-dashboard-font;
  padding: 0;
  border-radius: 8px;
  background: #fff;
  width: 890px !important;
  max-height: 90dvh !important;
  overflow-y: auto !important;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 18px;

    p {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(
      179deg,
      rgba(99, 11, 222, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    );
    border-radius: 4px 4px 0 0;
    color: #292929;
    padding: 16px 24px;
    width: 736px;
    height: 317.972px;
  }

  .steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    position: relative;

    .line {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #c7c9cb;
    }

    .step {
      z-index: 1;
      background: #c7c9cb;
      width: 36px;
      height: 36px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      border-radius: 50%;
      padding-bottom: 4px;
    }

    .active {
      background: #630bde;
    }
  }
}

.ant-modal-close-x {
  background: rgba(255, 255, 255, 0.5);
  width: 32px;
  height: 29.8px;
  border-radius: 50%;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anticon {
  font-size: 12px;
  color: #121212;
}

.ant-modal-footer {
  border-top: none;
  padding: 10px 0px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.confirm {
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  background: #630bde;
  padding: 16px 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.cancel {
  border: 1px solid rgba(99, 11, 222, 0.2);
  background: rgba(99, 11, 222, 0.1);
  color: #630bde;
}

.Retargeting {
  padding: 64px 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;

  .inputRowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;
    width: 48%;

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    label {
      color: #292929;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }

    .input {
      border-radius: 4px;
      border: 1px solid #e3e6ef;
      background: #fff;
      width: 100%;
      height: 48px;
      padding: 12px;
      font-size: 18px;
      font-weight: 500;
      outline: none;

      &::placeholder {
        color: #646464;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
}

.ShortLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: auto;
  margin-bottom: 3em;

  p {
    color: #646464;
    text-align: center;

    font-size: 18px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  button {
    color: #fff;
    font-size: 24px;
    border-radius: 8px;
    background: #630bde;
    padding: 16px 24px;
    border: none;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:hover {
      background: #630bdecc;
    }

    &:disabled {
      background: #630bde76;
    }
  }
}

.SMSPackage {
  .SMSContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .smsCard {
    display: flex;
    align-items: start;
    flex-direction: row-reverse;
    border-radius: 8px;
    border: 1px solid #c9c9c9;
    background: #fff;
    padding: 24px;
    margin-bottom: 24px;
    justify-content: space-between;

    .smsContent {
      display: flex;
      align-items: start;
      justify-content: start;
      gap: 19px;

      div:nth-child(2) {
        width: 80%;
      }

      img {
        width: 61px;
        height: 32px;
      }

      h5 {
        color: #121212;

        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
      }

      p {
        color: #646464;

        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }

    &:active,
    .ant-radio-wrapper-checked {
      border-radius: 8px;
      border: 1px solid #630bde;
      background: #fff;
    }
  }

  .SMSPackagePurchase {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: #292929;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }

    .SMSInput {
      display: flex;
      align-items: start;
      justify-content: flex-start;
      flex-direction: column;
      width: 50%;
    }

    .selectInput {
      border-radius: 4px;
      border: 1px solid #e3e6ef;
      background: #fff;
      width: 95%;
      height: 48px;
    }

    .flag {
      margin: 0em 0.4em;
    }
  }

  .ant-radio-wrapper-checked {
    border-radius: 8px;
    border: 1px solid #630bde !important;
    background: #fff;

    .ant-radio-inner {
      background: url("static/checkcircle.svg");
      width: 24px;
      height: 24px;
    }
  }
}
