@import "../../../styles/theme/app/variables";

.title {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 25px;
  position: relative !important;
  margin-bottom: 33px;
  // text-transform: none;
  // margin-left:-16px;
  font-size: 22px !important;
  &:before {
    position: absolute !important;
    content: "" !important;
    width: 70% !important;
    height: 1px !important;
    top: 14.5px !important;
    background: $agio-blue-grey-dark !important;
  }
  &.full {
    &:before {
      width: 100%;
    }
  }
  span {
    display: inline-block;
    background: $agio-white;
    text-transform: none !important;
    padding: 0 15px 0 0;
    position: relative;
    // left:14px;
    // color:#000f20 !important;
    // font-size:24px;
    // font-weight: bold;
  }
  :global {
    span {
      &.bg-grey-lightest {
        // background-color: $agio-grey-lightest;
      }
    }
  }
}

////////// resposive style

@media screen and (max-width: 1170px) and (min-width: 768px) {
  .title {
    font-size: 9px !important;
  }
}
