@import "../../../styles/theme/app/variables";

.label {
  color: #222;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.title {
  text-transform: uppercase;
  font-size: 12px;
}

.contain {
  position: relative;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 10px;

  .input {
    height: $main-input-height;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    padding: $main-input-padding;
    width: 100%;
    cursor: pointer;
    outline: none;
    font-size: $main-input-font-size;
    color: $main-input-clr;
    background-color: $main-input-background-clr;
    transition: all 0.3s ease-in-out;
  }

  .colorButton {
    top: 0;
    right: 0;
    width: $main-input-height;
    height: $main-input-height;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    cursor: pointer;
    outline: none;
    font-size: $main-input-font-size;
  }

  .popOver {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
  }
}
