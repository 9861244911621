@import "../../styles/theme/app/variables.scss";

.languagesContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
  margin-top: auto;
  font-weight: 500;

  > button {
    outline: none;
    border: none;
    background-color: transparent;
    color: $table-row-text-clr;
    transition: ease-in-out 300ms all;

    &:hover:not(.active) {
      text-decoration: underline;
      color: $main-purple-clr;
    }

    &.active {
      color: $main-purple-clr;
    }
  }
}
