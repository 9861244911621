@import "../../../../styles/theme/app/variables.scss";

.apiAndSdk {
  font-family: $main-dashboard-font;
  padding: 0 20px;

  .secondaryTitle {
    color: #000;
    font-weight: 700;
  }

  .regenerateButton {
    border-radius: 5px;
    background-color: $main-btn-clr;
    border: 1px solid $main-btn-clr;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    height: 42px;
    padding: 0 14px;
    outline: none;
    transition: all ease-out 300ms;

    &:hover {
      background-color: $main-btn-clr-hvr;
    }
  }
}
