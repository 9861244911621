@import "../../../styles/theme/app/variables";

.addModal {
  width: 905px;
  height: 420px;
  border-radius: 25px;
  :global {
    .modal-content {
      height: 100%;
      height: 420px;
      margin-top: 67px !important;
      border-radius: 25px;
      background-color: #ffffff;
      overflow: hidden;
      .modal-body {
        height: 420px;
        padding: 0px;
        .mainContent {
          height: 420px;
          padding: 0%;
        }
      }
    }
  }
  @media only screen and (max-width: 990px) {
    display: none;
  }
}

.slide {
  width: 150%;
  height: 150%;
  position: relative;
}
.bodycontant {
  height: 100px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #393939;
  width: 800px;
  padding-left: 50px;
}

.contanier {
  padding: 10px;
  background-color: #ffeef4;
  width: 210%;
  height: 60%;
}

.first_silder {
  margin: 0 auto;
  list-style: none;
  padding: 0;
  z-index: 1;
  margin-left: -14px;
  margin-right: -15px;
  margin-top: -16px;
  height: 248px;
  border-radius: 64px;
  object-fit: contain;
  background-color: #ffeef4;
}
.Reach_your {
  padding-left: 15px;
  padding-right: 2px;
  margin-top: -92px;
  margin-left: 87px;
  font-size: 17px;
  font-weight: 500;
  color: #393939;
  left: -84px;
  height: 40px;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-margin-start: 100px;
  margin-inline-start: 100px;
  padding-right: 50px;
  line-height: 1.5;
}
.Reach_your_Container {
  width: 90%;
}
///////////
.Reach_your_prag {
  margin-left: 19px;
}
////////////

.track {
  color: #024da1;
  font-size: 25px;
  font-weight: 700;
  margin-top: 125px;
  margin-left: 34px;
}

.bulidmobileappsfaster {
  margin-top: -40px;
  margin-bottom: 36px;
  margin-left: 87px;
  background-color: white;
  font-size: 17px;
  font-weight: 500;
  color: #393939;
  left: -90px;
  height: 40px;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  line-height: 1.5;
  // -webkit-margin-start: 100px;
  // margin-inline-start: 100px;
  line-height: 1.5;
  // position: relative;
}
.bulidmobileappsfasterContainer {
  margin-left: 39px;
}

// buttons
.skip {
  width: 30px;
  height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  text-align: left;
  color: #393939;
  cursor: pointer;
  text-decoration: none;
  float: left;
  position: absolute;
  bottom: -23px;
  outline: none;
  z-index: 1000000000;
  padding-left: 20px;
  font-weight: bold;
}
.next {
  width: 153px;
  height: 48px;
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 500;
  border-radius: 43px;
  background-image: linear-gradient(71deg, #024da1, #ed1650);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.34);
  position: absolute;
  bottom: -41px;
  outline: none;
  left: 77.7%;
  z-index: 100000000;
}
//////
.nextBtnSlideTwo {
  bottom: -47px;
}
//////
.done {
  width: 153px;
  height: 48px;
  color: #ffffff;
  cursor: pointer;
  left: 33px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 43px;
  background-image: linear-gradient(71deg, #024da1, #ed1650);
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.34);
  position: absolute;
  bottom: 15px;
  outline: none;
  z-index: 100000000;
  // position: relative;
  // left: 72%;
}
.createproject {
  width: 204px;
  height: 48px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 43px;
  background-image: linear-gradient(71deg, #024da1, #ed1650);
  position: absolute;
  bottom: 7px;
  left: 72%;
  z-index: 100000000;
  position: relative;
  outline: none;
}

// images
.check {
  width: 508px;
  height: 348px;
  object-fit: contain;
  margin-top: -145px;
  margin-left: 466px;
}
.people {
  padding-left: -14px;
  width: 564px;
  height: 327px;
  object-fit: contain;
  margin-top: 0px;
  margin-left: -217px;
  margin-left: -180px;
}
.basic {
  width: 500px;
  height: 300px;
  object-fit: contain;
  margin-top: -300px;
  margin-left: 500px;
}
