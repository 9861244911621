@import "./../bootstrap/variables";

$main-purple-clr: #630bde;
$main-border-clr: #9568d3;
$main-bg-clr: #f3f3f3;
$main-chip-bg-clr: rgba(99, 11, 222, 0.25);
$main-text-clr: rgba(41, 41, 41, 1);
$secondary-tex-clr: rgba(124, 140, 155, 1);

// new variables
$main-dashboard-font: "Lato", sans-serif;

// switch
$main-switch-bg-clr: rgba(7, 161, 113, 1);

// data table
$table-header-bg: #fff;
$table-header-text-clr: #9a9a9a;
$table-row-bg: #fff;
$table-row-text-clr: #474747;

// sidebar
$main-sidebar-bg-clr: #1f2f3f;
$sidebar-active-tab-bg: #8faaf5;

// font colors
$main-font-clr: #1f2f3f;
$gray-font-clr: #76767680;
$dark-grey-font-clr: #707070;
$error-text-clr: #f20000;
$helper-text-clr: #707070;
$label-text-clr: #292929;
$warning-text-clr: #cc3e4a;

// font sizes
$main-breadcrumb-font-size: 16px;
$main-button-font-size: 18px;
$secondary-button-font-size: 15px;
$main-font-size: 16px;
$secondary-font-size: 15px;

// input colors
$main-input-error-clr: #f20000;
$main-input-disabled-clr: #f5f5f5;
$main-input-border-clr: #bbb;
$main-input-border-radius: 4px;
$main-input-clr-focus: #1f2f3f;
$main-input-clr: #1f2f3f;
$main-input-background-clr-focus: #3f02a119;
$main-input-background-clr-disabled: #f5f5f5;
$main-input-background-clr: #fff;
$main-input-border-clr-focus: $main-purple-clr;
$main-input-font-size: 15px;
$main-input-font-weight: 700;
$main-input-border-clr-error: #cc3e4a;
$main-input-bg-clr-error: #cc3e4a1f;
$main-input-transition-duration: 500ms;
$main-input-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$main-input-padding: 10px;
$main-input-height: 48px;
$main-input-border: 1px solid $main-input-border-clr;
$main-input-border-clr-hvr: #630bde;
$main-input-placeholder-clr: #c7c9cb;
$main-input-placeholder-font-size: 20px;
$main-input-placeholder-font-weight: 500;

// primary button
$primary-btn-font-size: 18px;
$primary-btn-font-weight: 700;
$primary-btn-height: 42px;
$primary-btn-border-radius: 5px;
$primary-btn-clr: #fff;
$primary-btn-padding: 0 15px;

// page title
$page-title-font-size: 24px;
$page-title-font-weight: 700;
$page-title-font-clr: rgba(41, 41, 41, 1);

// radio
$main-radio-label-clr: #030d18;

// button colors
$main-btn-clr: #630bde;
$main-btn-clr-hvr: #630bdecc;
$main-btn-clr-disabled: #630bde76;
$main-btn-clr-delete: #f200001a;
$main-btn-clr-delete-hvr: #f2000036;
$main-btn-clr-delete-disabled: $main-btn-clr-disabled;
$main-btn-border-radius: 4px;
$main-btn-clr-border: 1px solid $main-btn-clr;
$main-btn-clr-txt: #fff;
$main-btn-font-size: 16px;
$main-btn-font-weight: 700;
$main-btn-padding: 0 12px;
$main-btn-height: 48px;
$main-btn-transition-duration: 500ms;
$main-btn-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

// colors
$main-bg-clr: #f5f5f5;
$overlay-bg-clr: #00000040;
$main-grey-clr: #76767640;
$main-delete-clr: #f20000;
$main-delete-clr-hvr: #c00404;

// appgain's variables
$agio-blue-dark: #003874;
$agio-blue: #034694;
$agio-blue-pale: #1a4c82;
$agio-blue-pale-light: #b9ddf0;
$agio-blue-active: #1a70fb;
$agio-blue-light: #1797e1;

$agio-green-blue: #33bcad;

$agio-pink: #ed1b52;

$agio-blue-grey-dark: #758699;

$agio-grey-darkest: #353535;
$agio-grey-semiLight: #e7e7e8;
$agio-grey-lighter: #ebebec;
$agio-grey-more-lighter: #f1f2f2;
$agio-grey-lightest: #f6f6f6;
$agio-grey: #f5f5f5;
$agio-white: #fff;

$body-bg: $agio-white;
$body-color: $agio-grey-darkest;
$sidebar-color: $agio-blue-dark;
//$sidebar-color: $agio-white;

$content-padding-x: 1rem;

$sidebar-item-icon-color: $agio-white;
$sidebar-item-color: $agio-white;

$sidebar-item-hover-icon-color: white;
$sidebar-item-hover-color: white;

$sidebar-active-color: white;
$sidebar-active-icon-color: white;

$sidebar-active-background: $agio-blue-pale;
$sidebar-active-accent-color: $agio-blue-light;

$sidebar-font-size: 16px;

$sidebar-border-width: 0;
$sidebar-border-color: #212121;
$sidebar-border: none;

$sidebar-width-default: 250px;
$sidebar-width-bigicons: 137px;
$sidebar-width-slim: 50px;

$navbar-border-color: #212121;
$navbar-height: 50px;

$right-sidebar-width: 300px;

$outer-submenu-width: 250px;

$outer-submenu-title-color: white;
$outer-submenu-title-size: 18px;
$outer-submenu-title-line: 1px solid $gray-dark;

$footer-height: 138px;
// $footer-height: auto;

$layout-settings-width: 200px;

$box-layout-width: 1200px;

//Responsive setup
$xs-min: 480px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;

$xs-max: ($sm-min - 1);
$sm-max: ($md-min - 1);
$md-max: ($lg-min - 1);

$phone: "only screen and (min-width: #{$xs-min})";
$phone-strict: "only screen and (min-width: #{$xs-min}) and (max-width: #{$xs-max})";
$tablet: "only screen and (min-width: #{$sm-min})";
$tablet-strict: "only screen and (min-width: #{$sm-min}) and (max-width: #{$sm-max})";
$desktop: "only screen and (min-width: #{$md-min})";
$desktop-strict: "only screen and (min-width: #{$md-min}) and (max-width: #{$md-max})";
$large: "only screen and (min-width: #{$lg-min})";
