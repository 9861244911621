.configureAutomators {
  .description {
    color: #646464;
    text-align: center;
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
  }

  .automatorsList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    padding: 0 20px;

    .automatorRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .automatorName {
        color: #292929;
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    label {
      margin: 0;
    }
  }
}
