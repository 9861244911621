@import "../../../styles/theme/app/variables";

.templates {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .content {
    padding: 0 24px;
  }
}

// create template

.createTemplate {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .content {
    padding: 0 24px;
    background-color: white;
    border-radius: 5px;
    margin: 24px;
    margin-bottom: 80px;

    .inputRow {
      margin: 24px 0;

      .emailGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }

      > p:first-child {
        color: $main-font-clr;
        font-size: $secondary-font-size;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .emailContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6px;
      }

      .fieldWithEmoji {
        width: 100%;
        position: relative;

        .emojiContainer {
          position: absolute;
          z-index: 99999999;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin: 18px 0;

    button {
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 15px !important;
      font-family: $main-dashboard-font;
      transition: color ease-in-out 150ms;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      height: 42px;
      padding: 0 14px;
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .cancelBtn {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      color: $main-btn-clr;
      background-color: white;
      border: none;
    }

    .submitBtn {
      height: 42px;
      background-color: $main-btn-clr;
      color: white;
      font-family: $main-dashboard-font;
    }

    .submitBtn:focus:enabled,
    .submitBtn:hover:enabled {
      background-color: $main-btn-clr-hvr;
    }

    .submitBtn:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }
  }

  .unlayerTemplatesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .templatesRow {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .templatesTitle {
      font-weight: bold;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > span {
        color: $dark-grey-font-clr;
        font-size: 13px;
      }
    }

    .template {
      border: 1px solid #eee;
      cursor: pointer;
      border-radius: 5px;
      overflow: hidden;

      .templateThumbnail {
        width: 180px;
        height: 180px;
        object-fit: contain;
      }

      .templateName {
        text-align: center;
        font-weight: bold;
        margin: 0;
        padding: 6px;
        font-size: 15px;
      }
    }
  }

  .unlayerButtonsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 12px 0;

    button {
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 15px !important;
      font-family: $main-dashboard-font;
      transition: color ease-in-out 150ms;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      height: 42px;
      padding: 0 14px;
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .clearBtn {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      color: $main-btn-clr;
      border: 1px solid $main-btn-clr !important;
      background-color: white;
      border: none;
    }

    .clearBtn:focus:enabled,
    .clearBtn:hover:enabled {
      background-color: $main-btn-clr;
      color: white;
    }
  }
}

.modalDialog {
  background-color: #fff;
  width: 80% !important;
  max-width: 650px;
  border-radius: 5px;
  font-family: $main-dashboard-font;
  color: $main-font-clr;
  max-height: 80vh;
  overflow: auto;

  .modalHeader {
    border-bottom: 1px solid #eee;
    color: $main-font-clr;
    padding: 15px;

    > button {
      color: $main-font-clr;
      font-size: 25px;
    }

    .modalTitle {
      font-weight: bold;
      font-size: 16px;
      color: $main-font-clr;
    }
  }

  .modalBody {
    padding: 15px;

    button {
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 15px !important;
      font-family: $main-dashboard-font;
      transition: color ease-in-out 150ms;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      height: 42px;
      padding: 0 14px;
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .form {
      width: 100%;
      font-family: $main-dashboard-font;

      .inoutRow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        margin: 24px 0;

        > label {
          font-size: 15px;
          color: $main-font-clr;
          font-weight: bold;

          span {
            font-size: 13px;
            color: #989898;
          }
        }

        input {
          border: 1px solid #1f2f3f;
          font-family: inherit;
          font-size: 16px;
          font-weight: normal;
          height: 48px;
          background-color: white;
          border-radius: 5px;
          padding: 0 8px;
          width: 100%;
        }

        input:focus,
        input:focus-within,
        input:active {
          border: 1px solid #033670;
          color: #033670;
        }
      }
    }

    .modalFooter {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-end;
      padding: 15px;

      .clearBtn {
        color: $main-btn-clr;
        border: 1px solid $main-btn-clr;
        background-color: white;
      }

      .clearBtn:focus:enabled,
      .clearBtn:hover:enabled {
        color: white;
        background-color: $main-btn-clr;
      }

      .saveBtn {
        color: white;
        background-color: $main-btn-clr;
        border: 1px solid $main-btn-clr;
      }

      .saveBtn:focus:enabled,
      .saveBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }
    }
  }
}
