@import "../../../../styles/theme/app/variables.scss";

.externalIntegrations {
  font-family: $main-dashboard-font;
  margin-bottom: 30px;

  .title {
    color: #000;
    font-weight: 700;
  }

  .fieldsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 12px;
    align-items: flex-start;
    max-width: 800px;
  }

  .row {
    .labelContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .label {
        font-size: 18px;
        font-weight: 700;
        color: $main-text-clr;
        margin: 0;
      }
    }

    .value {
      font-size: 16px;
      font-weight: 500;
      color: $secondary-tex-clr;
      margin: 0;
    }
  }

  .buttonsContainer {
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 20px;

    .editButton {
      background-color: #fff;
      border: 1px solid $main-purple-clr;
      color: $main-purple-clr;
      font-size: 16px;
      font-weight: 700;
      padding: 0 15px;
      height: 42px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-transform: capitalize;

      &:hover {
        background-color: $main-purple-clr;
        color: #fff;
      }
    }
  }
}

.modal {
  .inputRowContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    label {
      font-size: 16px;
      font-weight: 700;
      color: $main-text-clr;
      margin: 0;
    }

    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid $main-input-border-clr;
      padding: 0 15px;
      font-size: 16px;
      font-weight: 400;
      color: $main-input-clr;
      background-color: $main-input-background-clr;
      transition: all 0.3s ease-in-out;

      &:focus {
        border: 1px solid $main-input-border-clr-focus;
        outline: none;
        background-color: $main-input-background-clr-focus;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;

    button {
      background-color: #fff;
      border: 1px solid $main-purple-clr;
      color: $main-purple-clr;
      font-size: 16px;
      font-weight: 700;
      padding: 0 15px;
      height: 42px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-transform: capitalize;

      &:hover {
        background-color: $main-purple-clr;
        color: #fff;
      }
    }

    .cancelButton {
      background-color: #fff;
      border: 1px solid $main-purple-clr;
      color: $main-purple-clr;

      &:hover {
        background-color: $main-purple-clr;
        color: #fff;
      }
    }
  }
}
