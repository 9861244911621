@import "../../styles/theme/app/variables.scss";

.trailEndsContainer {
  height: 73px;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;

  > li {
    > a {
      text-decoration: none;
      cursor: default;
      color: #777;

      .trialEnds {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.upgradeButtonContainer {
  > a {
    padding: 6px !important;
  }
}

.container-fluid {
  padding: 0 !important;
}

.logo-main {
  position: absolute;
  cursor: pointer;
  left: 6.5rem;
  top: 10px;
  width: 90px;
}

.buttonUser {
  display: flex;
  align-items: center;

  > span {
    font-size: 18px;
    font-weight: 500;
  }
}

.buttonUser {
  & > div {
    width: 18px !important;
    height: 18px !important;
    & > img {
      position: absolute !important;
      height: 100% !important;
      width: 100% !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

.addCampaignBtn {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $main-dashboard-font;
  font-weight: 600;
  outline: none;
  gap: 8px;
  border-radius: 4px;
  transition: all ease-in-out 300ms;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid $main-purple-clr;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  background-color: $main-purple-clr;

  &:focus:enabled,
  &:hover:enabled {
    background-color: $main-btn-clr-hvr;
  }

  &:disabled {
    background-color: rgba(99, 11, 222, 0.25);
    color: rgba(41, 41, 41, 0.5);
    border: 1px solid rgba(99, 11, 222, 0.3);
  }

  > i {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .accountName {
    text-align: center !important;
    font-weight: bold !important;
    padding: 5px 0 !important;
  }
}

.account_btn_sty > ul > li > ul {
  background-color: #fff !important;
  & > li {
    & > a {
      font-weight: bold !important;
      color: #5c5c5c !important;
    }
  }
}

@media (max-width: 767px) {
  .account_btn_sty > ul > li > ul > p {
    border-top: 1px solid #c3c3c3;
  }

  .buttonUser span {
    display: none;
  }

  .account_btn_sty > ul > li > a {
    position: fixed;
    right: 0;
    top: 0;
    padding: 0 9px;
    padding-top: 8px;
    margin-top: 8px;
    padding-bottom: 11px;
    margin-right: 4px;
  }
  .nav > li > a {
    padding: 0;
  }
  .account_btn_sty > ul > li > ul > p {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding-top: 10px;
  }
  .dividerSty {
    background-color: #c3c3c3 !important;
  }
}

.upgrade-plan {
  color: #fff !important;
  background-color: #630bde !important;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 10px;
}

.remaining {
  color: #000 !important;
}
.poweredby {
  width: 55%;
  margin-top: 4em;

  p {
    font-size: 16px;
    text-align: center;
  }
  img {
    width: 100%;
  }
}
