@import "../../../styles/theme/app/variables";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000000;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $agio-white;
    font-size: 20px;
  }
  &.big {
    i {
      font-size: 40px;
    }
  }
}
