.noBackground {
  background: none;
}
@media (max-width: 767px) {
  .breadCrumbMob {
    & > li > a > li > a {
      font-size: 17px;
    }
    & > li::before {
      font-size: 17px !important;
    }

    & > li {
      & > span,
      a {
        font-size: 17px;
      }
    }
  }
}
