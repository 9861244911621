@import "../../../../styles/theme/app/variables";

.dateRange {
  text-align: center;
  padding-top: 20px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  padding-top: 20px;

  > button {
    height: 42px;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .modalCancel {
    border: 1px solid $main-btn-clr;
    color: $main-btn-clr;

    &:hover {
      background-color: $main-btn-clr;
      color: white;
    }
  }

  .modalConfirm {
    background-color: $main-btn-clr;
    color: white;

    &:hover:enabled {
      background-color: $main-btn-clr-hvr;
    }

    &:disabled {
      background-color: $main-btn-clr-disabled;
      border: 1px solid $main-btn-clr-disabled;
      cursor: not-allowed;
    }
  }
}

.description {
  color: grey;
  line-height: 28px;
  font-size: 16px;
  font-weight: bold;
}

.row {
  padding-right: 10px;
  padding-left: 10px;
  line-height: 5rem;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.row span:first-child {
  color: #a6a2a2;
}

.row:last-child {
  border-bottom: unset;
}

.hint {
  color: #a6a2a2;
}
