@import "../../../../styles/theme/app/variables.scss";

.primaryButton {
  font-family: $main-dashboard-font;
  height: $primary-btn-height;
  padding: $main-btn-padding !important;
  color: #fff !important;
  background-color: $main-btn-clr !important;
  border-radius: $main-btn-border-radius !important;
  transition: all $main-btn-transition-duration
    $main-btn-transition-timing-function;
  font-size: $main-btn-font-size;
  font-weight: $main-btn-font-weight;
  border: 1px solid $main-btn-clr !important;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover:enabled,
  &:focus:enabled,
  &:active:enabled,
  &:visited:enabled {
    color: #fff !important;
    background: $main-btn-clr-hvr !important;
    border-color: $main-btn-clr-hvr !important;
  }

  &:disabled {
    background-color: $main-btn-clr-disabled !important;
    border-color: transparent !important;
  }

  i {
    margin-right: 5px !important;
  }

  &.danger {
    background-color: #ff4d4f !important;
    border-color: #ff4d4f !important;

    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled,
    &:visited:enabled {
      background-color: #ff7875 !important;
      border-color: #ff7875 !important;
    }
  }
}
