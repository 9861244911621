$avatar-status-sm: 8px;
$avatar-status-default: 12px;
$avatar-status-lg: 16px;

$avatar-size-sm: 19px;
$avatar-size-default: 35px;
$avatar-size-lg: 84px;

.avatar {
  position: relative;
  width: $avatar-size-default;
  height: $avatar-size-default;
  display: inline-block;
  vertical-align: middle;

  .avatarContent {
    width: 100%;
    height: 100%;
  }

  &.avatarCircle > .avatarContent {
    border-radius: 50%;
  }

  &.avatarRounded > .avatarContent {
    border-radius: 6px;
  }

  .avatarPlaceholder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
  }

  .avatarText.avatarContent {
    font-size: 13px;
  }

  .infoWrap {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 6;
  }

  .avatarStatus {
    position: absolute;
    display: block;
    width: $avatar-status-default;
    height: $avatar-status-default;
    border: 2px solid;
    border-radius: 100%;
    top: -2px;
    right: 0;
    z-index: 6;

    &.avatarStatusBottom {
      top: auto;
      bottom: 0;
    }
  }

  &.avatarSm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;

    .avatarStatus {
      width: $avatar-status-sm;
      height: $avatar-status-sm;
      border-width: 1px;

      &.avatarStatusBottom {
        top: auto;
        bottom: -2px;
      }
    }

    .avatarPlaceholder {
      font-size: 11px;
    }

    .avatarText.avatarContent {
      font-size: 8px;
    }

    .infoWrap {
      font-size: 8px;
    }
  }

  &.avatarLg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;

    .avatarStatus {
      width: $avatar-status-lg;
      height: $avatar-status-lg;
      top: 3px;
      right: 6px;

      &.avatarStatusBottom {
        top: auto;
        bottom: 3px;
      }
    }

    .avatarPlaceholder {
      font-size: 48px;
    }

    .avatarText.avatarContent {
      font-size: 33px;
    }

    .infoWrap {
      top: 10px;
      right: 10px;
    }
  }
}
