.camapign-wizard {
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

.camapign-wizard-steps {
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
}

.wizard-step {
  font-size: 18px;
  padding: 2rem;
  color: #9a9a9a;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  &:hover {
    background-color: #e8f3fe;
    color: #5c5c5c;
  }

  & > div {
    height: 20px !important;
    width: 20px !important;
    border: 1px solid #000000;
    border-radius: 100%;
    cursor: pointer !important;
  }
  & > p {
    font-size: 2rem;
    margin-bottom: 0;
    display: none;
  }
  & > .active-radio {
    background-color: #630bde;
    border: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .wizard-step {
    & > p {
      display: inline-block;
    }
  }
}
